import { Component , OnInit , ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {DataService} from "./services/data.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'admin-QAs';
  showLoader : boolean = false;

  constructor(
  	public _router : Router,
    private _data : DataService,
  	public cdRef:ChangeDetectorRef
	){
  	}

	ngOnInit(){
    this._data.loaderInfo
        .subscribe(
          (val) => {this.showLoader = val ; this.cdRef.detectChanges() }
        );
  }


}
