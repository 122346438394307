import { Injectable , Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType , HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {DataService} from "../services/data.service";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  	token : any ;

    constructor(
	  	private http: HttpClient,
	    private _data : DataService,
		@Inject('API_URL') public API_URL: any 
  	){
        this.token = this._data.getCookiesByName('token');
  	}
}
