import { Injectable , Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType , HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataService } from "../services/data.service";

@Injectable({
    providedIn: 'root'
})
export class AppService {

	token : any ;

    constructor(
	  	private http: HttpClient,
	    private _data : DataService,
		@Inject('API_URL') public API_URL: any 
  	){
	    this.token = this._data.getCookiesByName('token');
  	}
    doLogin(accountLogin){
        return this.http.post<any>(`${this.API_URL}/oauth/token?grant_type=password&username=${accountLogin["username"]}&password=${accountLogin["password"]}` , null)
            .pipe(map(res => {
            return res;
        }));
    }
    getCreditConsumptionHistory(data){
        return this.http.post<any>(`${this.API_URL}/admin/credit/consumption/history`, data).pipe(map(res => {
            return res;
        }));
    }
    getCreditDeductionHistory(data){
        return this.http.post<any>(`${this.API_URL}/admin/credit/deduction/history`, data).pipe(map(res => {
            return res;
        }));
    }
    getCreditConsumptionSummary(data){
        return this.http.post<any>(`${this.API_URL}/admin/credit/consumption/summary`, data).pipe(map(res => {
            return res;
        }));
    }
    getDocs(data){
        return this.http.post<any>(`${this.API_URL}/standarddocs/all`, data).pipe(map(res => {
            return res;
        }));
    }
    updateDocs(data){
        return this.http.put<any>(`${this.API_URL}/standarddocs`, data).pipe(map(res => {
            return res;
        }));
    }
    getCountries(data){
        return this.http.post<any>(`${this.API_URL}/country/admin/all`, data).pipe(map(res => {
            return res;
        }));
    }
    updateCountry(data){
        return this.http.put<any>(`${this.API_URL}/country`, data).pipe(map(res => {
            return res;
        }));
    }
    getStandardChecks(){
        return this.http.get<any>(`${this.API_URL}/standardchecks/all`).pipe(map(res => {
            return res;
        }));
    }
    getSubscriptionPlans(data){
        return this.http.post<any>(`${this.API_URL}/payandsubq/get/subscription`, data).pipe(map(res => {
            return res;
        }));
    }
    createSubscriptionPlans(data){
        return this.http.post<any>(`${this.API_URL}/payandsubq/subscription`, data).pipe(map(res => {
            return res;
        }));
    }
    getPayAsYouGo(data){
        return this.http.post<any>(`${this.API_URL}/payandsubq/get/payasyougo`, data).pipe(map(res => {
            return res;
        }));
    }
    createPayAsYouGo(data){
        return this.http.post<any>(`${this.API_URL}/payandsubq/payasyougo`, data).pipe(map(res => {
            return res;
        }));
    }
    getSalesAndVouchers(data){
        return this.http.post<any>(`${this.API_URL}/payandsubq/get/sale`, data).pipe(map(res => {
            return res;
        }));
    }
    createSalesAndVouchers(data){
        return this.http.post<any>(`${this.API_URL}/payandsubq/sale`, data).pipe(map(res => {
            return res;
        }));
    }
  	getAllAplicationData(page, size){
  		return this.http.get<any>(`${this.API_URL}/applicationdata/all?page=${page}&size=${size}`).pipe(map(res => {
	        return res;
	    }));
  	}
    updateApplicationConfigData(data){
        return this.http.put<any>(`${this.API_URL}/applicationdata`, data).pipe(map(res => {
            return res;
        }));
    }
  	getAllClients(data){
  		return this.http.post<any>(`${this.API_URL}/clients/all`, data).pipe(map(res => {
	        return res;
	    }));
  	}
    getAllUsersClient(clientId, page, size, sort){
        return this.http.get<any>(`${this.API_URL}/clientsuser/${clientId}?page=${page}&size=${size}&sort=${sort}`).pipe(map(res => {
            return res;
        }));
    }
    updateClientStatus(clientusername, status){
        return this.http.put<any>(`${this.API_URL}/clients/${clientusername}/${status}`, {}).pipe(map(res => {
            return res;
        }));
    }


    // Standard Checks
    getAllStandardChecks(data){
        return this.http.post<any>(`${this.API_URL}/standardchecks/checks`, data).pipe(map(res => {
            return res;
        }));
    }


    updateAllStandardChecks(data){
        return this.http.put<any>(`${this.API_URL}/standardchecks/checks`, data).pipe(map(res => {
            return res;
        }));
    }


    // Standard Checks checkgroups
    getAllStandardCheckGroups(data){
        return this.http.post<any>(`${this.API_URL}/standardchecks/checkgroups`, data).pipe(map(res => {
            return res;
        }));
    }


    updateAllStandardCheckGroups(data){
        return this.http.put<any>(`${this.API_URL}/standardchecks/checkgroups`, data).pipe(map(res => {
            return res;
        }));
    }


    // Standard Checks links
    getAllStandardLinks(data){
        return this.http.post<any>(`${this.API_URL}/standardchecks/links`, data).pipe(map(res => {
            return res;
        }));
    }


    updateAllStandardLinks(data){
        return this.http.put<any>(`${this.API_URL}/standardchecks/links`, data).pipe(map(res => {
            return res;
        }));
    }


    // Taxes
    updateTaxes(data){
        return this.http.post<any>(`${this.API_URL}/admin/tax/update`, data).pipe(map(res => {
            return res;
        }));
    }


    getTaxes(data){
        return this.http.post<any>(`${this.API_URL}/admin/tax/get`, data).pipe(map(res => {
            return res;
        }));
    }

    createTaxes(data){
        return this.http.post<any>(`${this.API_URL}/admin/tax/create`, data).pipe(map(res => {
            return res;
        }));
    }


    // Taxes
    updateCurrencies(data){
        return this.http.put<any>(`${this.API_URL}/countrycurrencies`, data).pipe(map(res => {
            return res;
        }));
    }


    getCurrencies(data){
        return this.http.post<any>(`${this.API_URL}/countrycurrencies`, data).pipe(map(res => {
            return res;
        }));
    }

    createCurrencies(data){
        return this.http.post<any>(`${this.API_URL}/admin/currency/create`, data).pipe(map(res => {
            return res;
        }));
    }

    // get all invoices
    getInvoices(data){
        return this.http.post<any>(`${this.API_URL}/payandsubq/invoices`, data).pipe(map(res => {
            return res;
        }));
    }

    // Application Data
    getCompanySize(){
        return this.http.get<any>(`${this.API_URL}/constants/companysize`).pipe(map(res => {
                return res;
        }));   
    }

    getIndustryType(){
        return this.http.get<any>(`${this.API_URL}/constants/industrytype`).pipe(map(res => {
                return res;
        }));
    }

    getCompanyType(){
        return this.http.get<any>(`${this.API_URL}/constants/companytype`).pipe(map(res => {
                return res;
        }));
    }

    getStandardServices(){
        return this.http.get<any>(`${this.API_URL}/standardservice/service`).pipe(map(res => {
            return res;
        }));
    }

    addStandardServices(data){
        return this.http.post<any>(`${this.API_URL}/standardservice/service`, data).pipe(map(res => {
            return res;
        }));
    }

    updateStandardServices(data){
        return this.http.put<any>(`${this.API_URL}/standardservice/service`, data).pipe(map(res => {
            return res;
        }));
    }

    //Give extra Credit
    giveExtraCredit(data){
        return this.http.post<any>(`${this.API_URL}/admin/credit/aid`, data).pipe(map(res => {
            return res;
        }));
    }

    ///////////////////// STP Status //////////////////////
    getAdminConfigs(page,size){
        return this.http.get<any>(`${this.API_URL}/adminconfigs?page=${page}&size=${size}`).pipe(map(res => {
            return res;
        }));
    }
    setAdminConfigs(data){
        return this.http.put<any>(`${this.API_URL}/adminconfigs`,data).pipe(map(res => {
            return res;
        }));
    }

    getClientsConfigs(page,size){
        return this.http.get<any>(`${this.API_URL}/adminconfigs/clients?page=${page}&size=${size}`).pipe(map(res => {
            return res;
        }));
    }
    setClientsConfigs(data){
        return this.http.put<any>(`${this.API_URL}/adminconfigs`,data).pipe(map(res => {
            return res;
        }));
    }
    /////////////////// STP PENDING REVIEW //////////////////////

    getPendingReviewClients(page,size){
        return this.http.get<any>(`${this.API_URL}/userchecks/pending-review/clients?page=${page}&size=${size}`).pipe(map(res => {
            return res;
        }));
    }
    getPendingReviewClientsEndUsers(slug,page,size){
        return this.http.get<any>(`${this.API_URL}/userchecks/pending-review/clients/${slug}?page=${page}&size=${size}`).pipe(map(res => {
            return res;
        }));
    }
    getPendingReviewClientsEndUsersChecks(slug,checks,page,size){
        return this.http.get<any>(`${this.API_URL}/userchecks/pending-review/clients/${slug}/${checks}?page=${page}&size=${size}`).pipe(map(res => {
            return res;
        }));
    } 
    getBiometricVideo(checkGuid) {
        // const options = { responseType: 'blob'};
        // let options = { responseType: 'blob'};
        // let options : IRequestOptions = { headers: new HttpHeaders({"Content-Type": "application/octet-stream"}) , responseType: "blob"  } ;
        //   const httpOptions = {
        //   headers: new HttpHeaders({
        //     'Content-Type': 'application/octet-stream'
        //   }),
        //    responseType: 'blob',
        //   observe: 'response' as 'body'
        // };

        let headers = new HttpHeaders({
        "Content-Type": "application/octet-stream",
        });

        let authHttpOptions = {
        headers: headers,

        responseType: "blob",
        };
        return this.http.get(`${this.API_URL}/resolve/liveness/doc/${checkGuid}`, {
            headers: 
                new HttpHeaders({
                  "Content-Type": "application/octet-stream",
                }),
                responseType: "blob",
            }).pipe(map(res => { return res; })
        );
    }
    getLiveness(guid){
        return this.http.get<any>(`${this.API_URL}/resolve/liveness/${guid}`).pipe(map(res => {
            return res;
        }));
    }
    getSelfie(guid){
        return this.http.get<any>(`${this.API_URL}/resolve/selfie/${guid}`).pipe(map(res => {
            return res;
        }));
    }
    getSelfieDoc(guid){
        let headers = new HttpHeaders({
        "Content-Type": "application/octet-stream",
        });

        let authHttpOptions = {
        headers: headers,

        responseType: "blob",
        };
        return this.http.get(`${this.API_URL}/resolve/selfie/doc/${guid}`,{
            headers: 
                new HttpHeaders({
                  "Content-Type": "application/octet-stream",
                }),
                responseType: "blob",
            }).pipe(map(res => {
            return res;
        }));
    }
    getDocInfo(guid){
        return this.http.get<any>(`${this.API_URL}/resolve/doc/process/info/${guid}`).pipe(map(res => {
            return res;
        }));
    }
    getRearDocImage(guid){
        let headers = new HttpHeaders({
        "Content-Type": "application/octet-stream",
        });

        let authHttpOptions = {
        headers: headers,

        responseType: "blob",
        };
        return this.http.get(`${this.API_URL}/resolve/doc/back/${guid}`,{
            headers: 
                new HttpHeaders({
                  "Content-Type": "application/octet-stream",
                }),
                responseType: "blob",
            }).pipe(map(res => {
            return res;
        }));
    }
    getFrontDocImage(guid){
        let headers = new HttpHeaders({
        "Content-Type": "application/octet-stream",
        });

        let authHttpOptions = {
        headers: headers,

        responseType: "blob",
        };
        return this.http.get(`${this.API_URL}/resolve/doc/front/${guid}`,{
            headers: 
                new HttpHeaders({
                  "Content-Type": "application/octet-stream",
                }),
                responseType: "blob",
            }).pipe(map(res => {
            return res;
        }));
    }
    /////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////
    ///////////////LANGUAGES///////////////////////////////////////
    /////////
    createLanguage( data) {
        return this.http
            .post<any>(`${this.API_URL}/languages`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    getAllLAnguages(data){
        return this.http
            .get<any>(`${this.API_URL}/languages/all?page=0&size=100`)
            .pipe(
                map(res => {
                    return res;
                })
            );

    }
    updateLanguage(data) {
        return this.http
            .put<any>(`${this.API_URL}/languages`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
//////////////////////////////////THEMES/////////////////////////////////////////////////////////////

    createTheme( data) {
        return this.http
            .post<any>(`${this.API_URL}/themes`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    getAllThemes(data){
        return this.http
            .get<any>(`${this.API_URL}/themes/all?page=0&size=100`)
            .pipe(
                map(res => {
                    return res;
                })
            );

    }
    updateTheme(data) {
        return this.http
            .put<any>(`${this.API_URL}/themes`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    //////////////////////////////EMAIL//////////////////////////////////////////////////////////
    createEmail( data) {
        return this.http
            .post<any>(`${this.API_URL}/emails`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    getAllEmails(data){
        return this.http
            .get<any>(`${this.API_URL}/emails`)
            .pipe(
                map(res => {
                    return res;
                })
            );

    }
    updateEmail(data) {
        return this.http
            .put<any>(`${this.API_URL} /emails`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    /////////////////////////////////////////////////////////////
    ////////////////// ASSIGN PLANS /////////////////////////////
    assignPlan(clientId){
        return this.http
            .post<any>(`${this.API_URL}/payandsubq/subscribe/${clientId}`, null)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    ////////////////// PAY AS YOU GO *NEW* ////////////////////////////
    getAllPayAsYouGo(data){
        return this.http
            .post<any>(`${this.API_URL}/payment/v2/get/payasyougo`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    updatePayAsYouGo(data){
        return this.http.put<any>(`${this.API_URL}/payment/v2/update/payasyougo`, data).pipe(
            map(res => {
                return res;
            })
        );
    }
    setPayAsYouGo(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/create/payasyougo`, data).pipe(
            map(res => {
                return res;
            })
        );
    }
    ////////////////// LICENSE /////////////////////////////////////////////
    getAllLicence(data){
        return this.http
            .post<any>(`${this.API_URL}/payment/v2/get/licence`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    updateLicence(data){
        return this.http.put<any>(`${this.API_URL}/payment/v2/update/licence`, data).pipe(
            map(res => {
                return res;
            })
        );
    }
    setLicence(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/create/licence`, data).pipe(
            map(res => {
                return res;
            })
        );
    }
    ////////////////// LICENSE /////////////////////////////////////////////
    getAllUserLicence(data){
        return this.http
            .post<any>(`${this.API_URL}/payment/v2/get/userlicence`, data)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }
    updateUserLicence(data){ 
        return this.http.put<any>(`${this.API_URL}/payment/v2/update/userlicence`, data).pipe(
            map(res => {
                return res;
            })
        );
    }
    setUserLicence(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/create/userlicence`, data).pipe(
            map(res => {
                return res;
            })
        );
    }
    //////////////// CLIENT VERIFICATION //////////////////////////////////////////////
    clientVerification(data){
        return this.http.post<any>(`${this.API_URL}/clients/verify/${data}`, null).pipe(
            map(res =>{
                return res;
            })
        );
    }
    //////////////// PRICING HISTORY ////////////////////////////////////////////////////
    getAllBuyingHistory(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/buying/history`, data).pipe(
            map(res =>{
                return res;
            })
        );
    }

    getAllAutorenewalHistory(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/autorenewal/history`, data).pipe(
            map(res =>{
                return res;
            })
        );
    }

    getAllCronHistory(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/cron/history`, data).pipe(
            map(res =>{
                return res;
            })
        );
    }
    /////////////////////// ASSIGN PLAN ////////////////////////////////////////////////////
    assignLicence(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/admin/subscribe`, data).pipe(
            map(res =>{
                return res;
            })
        );    
    }

    assignPayAsYouGoPlan(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/admin/payasyougo/buy`, data).pipe(
            map(res =>{
                return res;
            })
        );    
    }
    
    giveExtraCreditV2(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/admin/extraCredit`, data).pipe(
            map(res =>{
                return res;
            })
        );    
    }

    getDeductionHistory(data){
        return this.http.post<any>(`${this.API_URL}/payment/v2/admin/deduction/history`, data).pipe(
            map(res =>{
                return res;
            })
        );
    }

    getDeductionSummary(aggr,data){
        aggr = (aggr != '') ? '/'+aggr : aggr;
        return this.http.post<any>(`${this.API_URL}/payment/v2/admin/deduction/summary`+aggr, data).pipe(
            map(res =>{
                return res;
            })
        );
    }
}
