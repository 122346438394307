import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})


// import { AppStorage } from '../forStorage/universal.inject';


export class DataService {

	private token = new BehaviorSubject<Object>('');
	tokenInfo = this.token.asObservable();

	public role = new BehaviorSubject<string>('');
	roleInfo = this.role.asObservable();

	public messageSource = new BehaviorSubject<string>('');
	currentMessage = this.messageSource.asObservable();

	public notiCount = new BehaviorSubject<number>(0);
	notiInfo = this.notiCount.asObservable();

	public showLoader = new BehaviorSubject<boolean>(false);
	loaderInfo = this.showLoader.asObservable();

  	changeMessage(message: string) {
    	this.messageSource.next(message)
  	}

  	changeNotiCount(number : number){
  		this.notiCount.next(number)
  	}

  	changeLoaderVisibility(shouldShow : boolean){
  		this.showLoader.next(shouldShow);
  	}

  	public layouts : any[] = [
  		{
  			"name"  : "" ,
  			"route" : "/dashboard" ,
  			"title" : "Dashboard"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/dashboard/clients" ,
  			"title" : "Clients"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/dashboard/application-data" ,
  			"title" : "Application Data"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/subscription-plan/get" ,
  			"title" : "Subscription Plan"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/subscription-plan/create" ,
  			"title" : "Create Subscription"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/payasyougo/get" ,
  			"title" : "Pay As You Go"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/payasyougo/create" ,
  			"title" : "Create Pay As You Go"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/sales-and-vouchers/get" ,
  			"title" : "Sales And Vouchers"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/sales-and-vouchers/create" ,
  			"title" : "Create Sales And Vouchers"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/dashboard/countries" ,
  			"title" : "Countries"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/dashboard/docs" ,
  			"title" : "Docs"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/admin-credit/credit-deduction-history" ,
  			"title" : "Credit Deduction History"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/admin-credit/credit-consumption-summary" ,
  			"title" : "Credit Consumption Summary"
  		},
  		{
  			"name"  : "" ,
  			"route" : "/admin-credit/credit-consumption-history" ,
  			"title" : "Credit Consumption History"
  		},
      {
        "name"  : "" ,
        "route" : "/standard-checks/checks" ,
        "title" : "Checks"
      },
      {
        "name"  : "" ,
        "route" : "/standard-checks/checkgroups" ,
        "title" : "Check Groups"
      },
      {
        "name"  : "" ,
        "route" : "/standard-checks/links" ,
        "title" : "Links"
      },
      {
        "name"  : "" ,
        "route" : "/stats" ,
        "title" : "Statastics"
      },
      {
        "name"  : "" ,
        "route" : "/tax" ,
        "title" : "Tax"
      },
      {
        "name"  : "" ,
        "route" : "/currency" ,
        "title" : "Currency"
      },
      {
        "name"  : "" ,
        "route" : "/invoices" ,
        "title" : "Invoices"
      },

      {
        "name"  : "" ,
        "route" : "/standard-service/get" ,
        "title" : "Standard Service"
      },
      {
        "name"  : "" ,
        "route" : "/standard-service/create" ,
        "title" : "Create Standard Service"
      },
      {
        "name"  : "" ,
        "route" : "/extra-credit" ,
        "title" : "Give Extra Credit"
      },
      {
        "name"  : "" ,
        "route" : "/pay-as-you-go" ,
        "title" : "Pay As You GO *NEW*"
      },
      {
        "name"  : "" ,
        "route" : "/pay-as-you-go/create" ,
        "title" : "Pay As You GO *NEW* : Create"
      },
      {
        "name"  : "" ,
        "route" : "/licence" ,
        "title" : "Licence"
      },
      {
        "name"  : "" ,
        "route" : "/licence/create" ,
        "title" : "Licence : Create"
      },
      {
        "name"  : "" ,
        "route" : "/user-licence" ,
        "title" : "User Licence"
      },
      {
        "name"  : "" ,
        "route" : "/user-licence/create" ,
        "title" : "User Licence : Create"
      },
      {
        "name"  : "" ,
        "route" : "/client-verification" ,
        "title" : "Client Verification"
      },
      {
        "name"  : "" ,
        "route" : "/pricing-history" ,
        "title" : "Pricing History : Cron"
      },
      {
        "name"  : "" ,
        "route" : "/pricing-history/buying" ,
        "title" : "Pricing History : Buying"
      },
      {
        "name"  : "" ,
        "route" : "/pricing-history/autorenewal" ,
        "title" : "Pricing History : Autorenewal"
      },
      {
        "name"  : "" ,
        "route" : "/assign-plan" ,
        "title" : "Assign Plan : Give Licence"
      },
      {
        "name"  : "" ,
        "route" : "/assign-plan" ,
        "title" : "Assign Plan : Give Licence"
      },
      {
        "name"  : "" ,
        "route" : "/assign-plan/pay-as-you-go" ,
        "title" : "Assign Plan : Pay As You Go"
      },
      {
        "name"  : "" ,
        "route" : "/assign-plan/extra-credit" ,
        "title" : "Assign Plan : Give Extra Credit"
      }
  	]

	constructor(
		private toastr: ToastrService,
		private _cookieService:CookieService
	) {
		 
	}

	toTitleCase(str){
	    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
	}
	getCurrency(acronym){
        let acronymm = acronym.toLowerCase();
        if(acronymm === 'gbp'){
            return '&pound;'
        }else if(acronymm === 'inr'){
            return 'INR'
        }else if(acronymm === 'usd'){
            return '$'
        }else if(acronymm === 'aed'){
            return 'AED'
        }else{
            return '&pound;'
        }
    }
    getCurrencies(){
    	return ['GBP', 'INR', '$', 'AED'];
    }
	getSortBys(){
		return [{
			key : 'Recommended',
			value : ''
		}, {
			key : 'Highest Discount',
			value : 'sale'
		}, {
			key : 'Price high - low',
			value : 'desc'
		}, {
			key : 'Price low - high',
			value : 'asc'
		}];
	}
	priceFormatting(priceform){
    	let price = parseInt(priceform);
        if(Math.round(price) === price){
            return price;
        }else{
            return price.toFixed(2);
        }
    }
	
	changeRole(role: any) {
		this.role.next(role);
	}

	changeToken(token: any) {
		this.token.next(token);
	}

	getCookiesByName(slug : any){
	    return this._cookieService.get(slug);
	}

	getCookieObjectByName(slug : any){
	    return this._cookieService.getObject(slug);
	}

	setCookieByName(slug : any , value : any , expire? : any){
		let expires;
		if(expire instanceof Date){
			expires = expire
		}else if(!expire){
			expires = '';
		}else{
			expires = this.getCookieSessionTime(expire);
		}
		this._cookieService.put(slug , value , {
			expires : expires
		});
	}

	setCookieObject(slug : any , value : any , expire : any){
		let expires;
		if(expire instanceof Date){
			expires = expire
		}else if(!expire){
			expires = '';
		}else{
			expires = this.getCookieSessionTime(expire);
		}
		this._cookieService.putObject(slug , value , {
			expires : expires
		});
	}

	removeAllCookie(){
		this._cookieService.removeAll();
	}

	getCookieSessionTime(days : any){
		var session = new Date();
		days = days ? days : 1 ;
		session.setTime(session.getTime() + days*24*60*60*1000);
        return session;
	}

	toastrShow(message : any, type : any){
    	this.toastr.clear();
    	if(type === 'info'){
    		this.toastr.info('', message);
    	}else if(type === 'error'){
    		this.toastr.error('', message);
    	}
    }

    getTitle(route){
    	var title  = this.layouts.filter(obj=> obj.route == route);
    	if(title && title.length){
    		return this.layouts.filter(obj=> obj.route == route)[0].title ;
    	}
    	else return "User Details" ;
    }
}